<template>
  <div class="dashboard-editor-container uploader-file" :status="status">
    <div class="title">
      <el-upload
        class="upload-demo"
        action=""
        ref="upload"
        :show-file-list="false" 
        :file-list="fileList"
        :limit="5"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :auto-upload="false"
        accept=""
      >
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitForm">上传到服务器</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="resumeUpload">继续</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="stopUplosd">暂停</el-button> -->
        <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="abortMultipartUpload">清除切片</el-button> -->
      </el-upload>
    </div>
    <div class="file">
       <div  v-for="(item,index) in fileList" :key="index">
         <div class="uploader-file-info">
            <div class="uploader-file-progress" :class="progressingClass" :style="progressStyle"></div>
            <div class="uploader-file-name">
              <i class="uploader-file-icon" icon=""></i>{{item.name}}
            </div>
        
            <div class="uploader-file-size">{{bytesToSize(item.size)}}</div>
            <div class="uploader-file-meta"></div>
            <div class="uploader-file-status">
              <span v-show="status !== 'uploading'">{{statusText[status]}}</span>
              <span v-show="status === 'uploading'">
                <span>{{ progressStyle.progress }}</span>
              </span>
            </div>
            <div class="uploader-file-actions">
              <span class="uploader-file-pause" @click="pause"></span>
          <span class="uploader-file-resume" @click="resume">️</span>
          <span class="uploader-file-retry" @click="retry"></span>
          <span class="uploader-file-remove" @click="remove"></span>
            </div>
          </div>
       </div>
    </div>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";
  let OSS = require('ali-oss') // 引入ali-oss插件
//   const client = new OSS({
//     region: 'oss-cn-shanghai',//根据那你的Bucket地点来填写
//     accessKeyId: 'LTA*********RaXY',//自己账户的accessKeyId
//     accessKeySecret: 'uu1************GiS',//自己账户的accessKeySecret
//     bucket: 'a******o',//bucket名字
//   });
export default {
  data () {
    return {
      // status:'',
      statusText: {
        success: "成功了",
        error: "出错了",
        uploading: "上传中",
        paused: "暂停中",
        waiting: "计算md5...",
      },
      paused: false,
      error: false,
      isComplete: false,
      isUploading: false,
      progressingClass: "",



      client:null,
      fileList:[],
      file: null,
      tempCheckpoint:null, // 用来缓存当前切片内容
      uploadId: '',
      uploadStatus: null, // 进度条上传状态
      percentage: 0, // 进度条百分比
      uploadName: '',  //Object所在Bucket的完整路径
      type:null,
      options:{
        chunkNumber:null, //当前文件块，从1开始
        chunkSize:10*1024*1024, //分块大小
        currentChunkSize:null, //当前分块大小
        totalSize:null, //总大小
        identifier:null, //文件标识
        currentIdentifier:null, //当前文件标识
        filename:null, //文件名
        relativePath:null, //文件路径（阿里云路径）
        totalChunks:null, //总块数
        type:null, //文件类型
        chunkData:null //文件数据

      }

    }
  },
  computed: {
    status() {
      const isUploading = this.isUploading;//上传中
      const isComplete = this.isComplete;//上传完成
      const isError = this.error;//错误
      const paused = this.paused;//暂停
      if (isComplete ) {
        return "success";
      } else if (isError) {
        return "error";
      } else if (isUploading) {
        return "uploading";
      } else if (paused) {
        return "paused";
      } else {
        return "waiting";
      }
    },
    progressStyle() {
      const progress = Math.floor(this.percentage * 100);
      const style = `translateX(${Math.floor(progress - 100)}%)`;
      return {
        progress: `${progress}%`,
        webkitTransform: style,
        mozTransform: style,
        msTransform: style,
        transform: style
      };
    },
  },
  created(){
      this.getQueryToken()
  },
  watch: {
    status(newStatus, oldStatus) {
      if (oldStatus && newStatus === "uploading" && oldStatus !== "uploading") {
        this.tid = setTimeout(() => {
          this.progressingClass = "uploader-file-progressing";
        }, 200);
      } else {
        clearTimeout(this.tid);
        this.progressingClass = "";
      }
    }
  },
  mounted() {
      // this.getQueryToken()
    // window.addEventListener('online',  this.resumeUpload);
  },
  methods: {
    bytesToSize(bytes) {
      if (bytes === 0) return '0 B';
        var k = 1024, // or 1024
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },

    //继续
     pause() {
      this._filePaused()
      this.stopUplosd()
      console.log(123)
    },
    //暂停
    resume() {
      this._fileIsUploading()
         this.getQueryToken()
      setTimeout(()=>{
          this.resumeUpload()
        },1000)
      
      console.log(1234)
    },
    //移除
    remove() {
      console.log(12345)
    },
    //重新上传
    retry() {
      console.log(123456)
    },
    _fileComplete() {
      this.error = false;
      this.isComplete = true;
      this.isUploading = false;
      this.paused = false;
      this.percentage =1;
    },
    _fileError() {
      this.error = true;
      this.isComplete = false;
      this.isUploading = false;
      this.paused = false;
    },
    _fileIsUploading() {
      this.error = false;
      this.isComplete = false;
      this.isUploading = true;
      this.paused = false;
    },
    _filePaused() {
      this.error = false;
      this.isComplete = false;
      this.isUploading = false;
      this.paused = true;
    },
    async getCheck(res,file) {
      let data = {
        identifier:res,
        projectId:this.$store.state.user.currentProject.id,
        sourceType:-1
      }
      let resData = await this.$Api.OssUpload.getCheck(data)
      console.log(resData)
      if (!resData.data.check && !resData.data.fileInfo && !resData.data.chunkInfo) {
        this.submitForm(file)
      }
      if (resData.data.check && resData.data.chunkInfo) {
        this.tempCheckpoint = JSON.parse(resData.data.chunkInfo.chunkData)
        this.resumeUpload()
      }
      if (resData.data.check && resData.data.fileInfo) {
        this._fileComplete()
      }
      
    },
    async postCheck(options) {
      let data = options
      let resData = await this.$Api.OssUpload.postCheck(data)
    },
    async postMerge(options,sourceType) {
          let data =   {
          filename:options.filename,//文件名
          identifier:options.identifier,//文件标识
          totalSize:options.totalSize,//总大小
          type:this.type,//请求类型
          location:options.relativePath,//文件路径（阿里云路径）
          ext:options.type, //文件类型
          projectId:this.$store.state.user.currentProject.id,
          sourceType
      }
      
      let resData = await this.$Api.OssUpload.postMerge(data)
      this._fileComplete()
    },
    computeMD5(fileRaw,file) {
      let that = this;
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let chunkSize = 10*1024*1024
      let chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
          console.log(
            `第${currentChunk}分片解析完成, 开始第${currentChunk +
              1} / ${chunks}分片解析`
          );
        } else {
          let md5 = spark.end(); //得到md5
          function getCaption(obj) {
            var index = obj.lastIndexOf(".");
            obj = obj.substring(index + 1, obj.length);
            return obj;
          }
          let filemd5 = md5 + "_" + file.size + "_" + getCaption(file.name);

          that.options.currentIdentifier = md5;
          that.options.identifier = filemd5;
          that.options.totalSize = file.size;
          that.options.filename = file.name;
          that.options.totalChunks = currentChunk;
          that.options.type = getCaption(file.name);
          that.getCheck(filemd5,file)
          console.log('computed hash',filemd5)
        }
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      function loadNext () {
        let start = currentChunk * chunkSize
        let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        // 注意这里的 fileRaw
        fileReader.readAsArrayBuffer(blobSlice.call(fileRaw, start, end))
      }
      loadNext()
    },
      async getQueryToken() {
           let resData = await this.$Api.OssUpload.getQueryToken()
           this.client = new OSS({
            //  secure:true,
            region: 'oss-cn-beijing',//根据那你的Bucket地点来填写
            accessKeyId: resData.data.accessKeyId,//自己账户的accessKeyId
            accessKeySecret: resData.data.accessKeySecret,//自己账户的accessKeySecret
            bucket: resData.data.bucket,//bucket名字
            stsToken: resData.data.stsToken
          });
      },
    // 点击上传至服务器
    submitForm(file) {
      this.multipartUpload();
    },
    // 取消分片上传事件
    async abortMultipartUpload() {
      window.removeEventListener('online', this.resumeUpload)
      const name = this.uploadName; // Object所在Bucket的完整路径。
      const uploadId = this.upload; // 分片上传uploadId。
      const result = await this.client.abortMultipartUpload(name, uploadId);
      console.log(result, '=======清除切片====');
    },
    // 暂停分片上传。
    stopUplosd () {
      window.removeEventListener('online', this.resumeUpload) // 暂停时清除时间监听
      let result = this.client.cancel();
      console.log( result, '---------暂停上传-----------')
    },
    
    // 切片上传

    async multipartUpload () {
      if (!this.file) {
        this.$message.error('请选择文件')
        return
      }
      this.uploadStatus = null
      this.percentage = 0
      try {
        this._fileIsUploading()
        //object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let result = await this.client.multipartUpload(this.file.name, this.file, {
          headers: {
            'Content-Disposition': 'inline',
            'Content-Type': this.file.type //注意：根据图片或者文件的后缀来设置，我试验用的‘.png’的图片，具体为什么下文解释
          },
          progress: (p, checkpoint) => {
            console.log(checkpoint)

            this.tempCheckpoint = checkpoint;
            this.options.chunkData = JSON.stringify(this.tempCheckpoint);
            this.options.chunkNumber = checkpoint.doneParts.length;
            this.options.currentChunkSize = checkpoint.partSize;
            this.options.relativePath = checkpoint.uploadId;
            this.type = checkpoint.file.type;
            this.upload = checkpoint.uploadId
            this.uploadName = checkpoint.name
            this.percentage = p
            console.log(p, checkpoint, this.percentage, '---------uploadId-----------')
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            this.postCheck(this.options)

          },
          partSize:10*1024*1024,
          parallel:1,
          meta: { year: 2021, people: 'test' },
          mime: this.file.type
        });
        console.log(result, this.percentage, 'result= 切片上传完毕=');
        this.postMerge(this.options)
        


      } catch (e) {
        window.addEventListener('online',  this.resumeUpload) // 该监听放在断网的异常处理
        // 捕获超时异常。
        if (e.code === 'ConnectionTimeoutError') { // 请求超时异常处理
          this.uploadStatus = 'exception'
          console.log("TimeoutError");
          // do ConnectionTimeoutError operation
        }
        // console.log(e)
      }
    },
    // 恢复上传。
    async resumeUpload () {
      window.removeEventListener('online', this.resumeUpload)
      if (!this.tempCheckpoint) {
        this.$message.error('请先上传')
        return
      }
      this.uploadStatus = null
      
      try {
        this._fileIsUploading()
        let result = await this.client.multipartUpload(this.file.name, this.file, {
          headers: {
            'Content-Disposition': 'inline',
            'Content-Type': this.file.type //注意：根据图片或者文件的后缀来设置，我试验用的‘.png’的图片，具体为什么下文解释
          },

          progress: (p, checkpoint) => {
            this.percentage = p
            console.log(p, checkpoint, 'checkpoint----恢复上传的切片信息-------')
            this.tempCheckpoint = checkpoint;
            this.options.chunkData = JSON.stringify(this.tempCheckpoint);
            this.options.chunkNumber = checkpoint.doneParts.length;
            this.options.currentChunkSize = checkpoint.partSize;
            this.options.relativePath = checkpoint.uploadId;
            this.type = checkpoint.file.type;
            this.postCheck(this.options)
          },
          checkpoint: this.tempCheckpoint,
          partSize:10*1024*1024,
          parallel:1,
          meta: { year: 2021, people: 'test' },
          mime: this.file.type
        })
        console.log(result, 'result-=-=-恢复上传完毕')
        this.postMerge(this.options)
      } catch (e) {
        console.log(e, 'e-=-=-');
      }
    },
    // 选择文件发生改变
    handleChange(file, fileList) {
      // this.fileList = fileList;
      this.fileList = fileList.filter(row => row.uid == file.uid)
      this.file = file.raw
      this.computeMD5(this.file,file)
      // // 文件改变时上传
      // this.submitForm(file)
    },
    handleRemove(file, fileList) {
      this.percentage = 0 //进度条置空
      this.fileList = []
    },
  }
}
</script>

<style scoped>
.uploader-file {
  position: relative;
  height: 49px;
  line-height: 49px;
  overflow: hidden;
  border-bottom: 1px solid #cdcdcd;
}

/* .uploader-file[status="waiting"] .uploader-file-pause, */
.uploader-file[status="uploading"] .uploader-file-pause {
  display: block;
}

.uploader-file[status="paused"] .uploader-file-resume {
  display: block;
}

.uploader-file[status="error"] .uploader-file-retry {
  display: block;
}

.uploader-file[status="success"] .uploader-file-remove {
  display: none;
}

.uploader-file[status="error"] .uploader-file-progress {
  background: #ffe0e0;
}

.uploader-file-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #e2eeff;
  transform: translateX(-100%);
}

.uploader-file-progressing {
  transition: all 0.4s linear;
}

.uploader-file-info {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;
}

.uploader-file-info:hover {
  background-color: rgba(240, 240, 240, 0.2);
}

.uploader-file-info i,
.uploader-file-info em {
  font-style: normal;
}

.uploader-file-name,
.uploader-file-size,
.uploader-file-meta,
.uploader-file-status,
.uploader-file-actions {
  float: left;
  position: relative;
  height: 100%;
}

.uploader-file-name {
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-indent: 14px;
}

.uploader-file-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
  margin-right: 8px;
}

.uploader-file-icon::before {
  content: "📃";
  display: block;
  height: 100%;
  font-size: 24px;
  line-height: 1;
  text-indent: 0;
}

.uploader-file-icon[icon="folder"]::before {
  content: "📂";
}

.uploader-file-icon[icon="image"]::before {
  content: "📊";
}

.uploader-file-icon[icon="video"]::before {
  content: "📹";
}

.uploader-file-icon[icon="audio"]::before {
  content: "🎵";
}

.uploader-file-icon[icon="document"]::before {
  content: "📋";
}

.uploader-file-size {
  width: 13%;
  text-indent: 10px;
}

.uploader-file-meta {
  width: 8%;
}

.uploader-file-status {
  width: 24%;
  text-indent: 20px;
}

.uploader-file-actions {
  width: 10%;
}

.uploader-file-actions > span {
  display: none;
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 16px;
  margin-right: 10px;
  cursor: pointer;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAABkCAYAAAD0ZHJ6AAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAACxMAAAsTAQCanBgAAARkSURBVGje7ZnfS1NRHMAH4ptPkvQSuAdBkCxD8FUQJMEULUgzy1KyyPVQ4JMiiP4Bvg6EwUQQfMmwhwRDshwaKUjDVCgoSdDNHkzTJZ6+Z37Purve8+PeTb2TM/ggu+ew89l33x8H9BBCPG7GowXTJej3+wnDvEm0JuLC04+EYWftVAUv+fiCvDUdQR1BHUEdQR3BTIygvixoQS14XgTtthLVdpNWwXRLqvQ724LplFRtyrYF0yVpFLQrKRVMh6RZ0I6kkmCqklaCqpKZH0FX56Crq9jVfdDVk0RfFrSgFsxkQVmLcdKCVrKySCrryhPEyYShhzOcrFtG0EoilfHHk1CRU5rF6ZjNZhlVOW6RnMSVyyilKies4pO41diVy8wIujoHXV3FGdMHXTtJKLFYTLhZtq4vC1rwXApCZTIqgR6g1PBMCO9DL3bMMSqBHqDU8EyISDAHiGKvWwcCQG2KgjlAFCDAOhAAap0K5gKLphk8mqJgLrCIgoxRJ4J5wKpJ7gAoMkn5EBXBPGDVJHcAFJmkfIhQcAql1oBpTvTol9gG9pm4RHAKpdaAaU706JfYBvaZuJVgPQrt4sFlnOh5MC/p3lmJYD0K7eLBZZzoeTAv6d5ZnuAYHjpgEOnk5F0ufhG6v1ggOIaHDhhEOjl5l4tfhO4vthLcwAMrFNvLJO5vEwhu4IEViu1lEve3WQmyoihQFBzG/V0CQVYUBYqCw7i/SxTBcpsRbFeIYLnNCLZbCY5b5KAnxRwct8hBj9McZFVMW0ihRNBuFdMWUigRlFaxuQ9WWYjRMTiIe5z0wSoLMToGB3GPsA9aTZIJoB+nRgBnM1tzOkkmgH6cGgGczWzNpzqLx3n/aULJJgezeNw07oxQySbVywKjBOgFRnDs+VEsx8FlgVEC9AIjOPb8KJYjvSzoG7UW1IJaUAtqQS14toLNM5fN5APdwBJA8G83Pk/aK/rgzVvXzeQD3cASQPBvNz5P2ssTzAaGUIrHEO6zI5gNDKEUjyHcxxWkh4Ylcowwk1QQpIeGJXKMMJO0EgwqyjGCioJBJvDrxRMSuVOTJEXfbz1/bHwWtBL0yoQehK6RucgE+bGzanzulQh6E3IgQV+xpc8kcrfuSO7eTfJ3ZYmQw0Oy9azVKOk1C/bJ5D5F38YPeLfx0rjWJxHsS0SqsSYuxySjj5qO5Oj7xQWy2VBtFOwzCy6ryH3YfE3uh64Y1xckgstJPydEjkkeHv07Iy4Xaao15+KCWTBx6M/db+T9xivSErqaJDdzXI6yLRE8Vgg0coex/SPJvT0SbWu0KpZtbgSpCH3NRt7I5OxHkObc6heU+/M/J5vrpBFM5GBLqCQux14COXs5CNXK5OjPGm1tSMrJSOMNYQ4mVTGV/L6zTL7+DovkbFUxbSW0Wo05l8hJWsU+cRWfSh+Mt5Lb1ck/J1TvVsdDaR/MiEni+llsdZuZp62EViu+96bpNjNPWwmtVnzvFd5m9IVVC54x/wA7gNvqFG9vXQAAAABJRU5ErkJggg==")
    no-repeat 0 0;
}

.uploader-file-actions > span:hover {
  background-position-x: -21px;
}

.uploader-file-actions .uploader-file-pause {
  background-position-y: 0;
}

.uploader-file-actions .uploader-file-resume {
  background-position-y: -17px;
}

.uploader-file-actions .uploader-file-retry {
  background-position-y: -53px;
}

.uploader-file-actions .uploader-file-remove {
  display: block;
  background-position-y: -34px;
}
</style>
